<template>
    <div>
        <!-- CRU Modal -->
        <b-modal
            id="cru_modal"
            :title="`${
                cru_modal.prize.id
                    ? `Update ${cru_modal.prize.position}${get_position_suffix(
                        cru_modal.prize.position
                                            )} Place Prize`
                    : 'Create Prize'
            }`"
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <input
                                    type="submit"
                                    ref="cru_modal_form_submit_button"
                                    hidden
                                />
                                <b-row>
                                    <b-col sm="3" class="pr-1">
                                        <el-tooltip
                                            content="Placement in the competition, e.g. 1st, 2nd, 3rd"
                                            placement="top"
                                        >
                                            <base-input
                                                label="Place"
                                                :rules="{
                                                    required: true,
                                                    numeric: true,
                                                    min_value: 1,
                                                }"
                                                :pill="true"
                                                type="number"
                                                name="Place"
                                                placeholder="0"
                                                :disabled="$apollo.loading"
                                                v-model="
                                                    cru_modal.prize.position
                                                "
                                            >
                                            </base-input>
                                        </el-tooltip>
                                    </b-col>
                                    <b-col class="pt-4 mt-3 pl-0">
                                        {{
                                            get_position_suffix(
                                                cru_modal.prize.position
                                            )
                                        }}
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <html-editor
                                            :pill="true"
                                            label="Prize detail description"
                                            class="mb-3"
                                            name="Detailed Description"
                                            placeholder="A detail description"
                                            v-model="
                                                cru_modal.prize.description
                                            "
                                            :disabled="$apollo.loading"
                                        >
                                        </html-editor>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('cru_modal')"
                            :disabled="cru_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="cru_modal_save()"
                            :disabled="cru_modal.loading"
                            :loading="cru_modal.loading"
                            :success="cru_modal.success"
                            >Save
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal
            id="d_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the prize for position:
                    {{ d_modal.prize.position }}
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="delete_prize()"
                    :loading="d_modal.loading"
                    :disabled="d_modal.loading"
                    :success="d_modal.success"
                >
                    Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('d_modal')"
                    :disabled="d_modal.loading"
                    >Cancel
                </base-button>
            </div>
        </b-modal>

        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <h1>Hackathon Prizes</h1>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <el-tooltip
                            content="Create Prize"
                            placement="top"
                            v-if="is_hackathon_manager || is_hackathon_creator"
                        >
                            <base-button
                                type="primary"
                                round
                                icon
                                size="sm"
                                @click="cru_modal_show()"
                            >
                                <span class="btn-inner"
                                    ><i class="fa fa-plus"></i
                                ></span>
                            </base-button>
                        </el-tooltip>
                    </b-col>
                </b-row>

                <div v-if="$apollo.loading && table_data.length == 0">
                    <b-row
                        class="mt-2 d-flex align-items-center justify-content-center"
                    >
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Transition>
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </Transition>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-else>
                    <b-col>
                        <el-table
                            class="table-responsive align-items-center table-flush no-lines"
                            header-row-class-name="thead-light"
                            row-class-name="table-rows"
                            :data="table_data"
                            :empty-text="`${
                                is_hackathon_manager || is_hackathon_creator
                                    ? 'No prizes yet, use the \'+\' button to add a prize.'
                                    : 'Good things are coming...'
                            }`"
                        >
                            <el-table-column
                                label="Place"
                                min-width="80px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0">
                                        {{ row.position
                                        }}{{
                                            get_position_suffix(row.position)
                                        }}
                                    </p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Details"
                                min-width="240px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p
                                        class="mb-0"
                                        v-html="row.description"
                                    ></p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                min-width="180px"
                                align="center"
                                v-if="
                                    is_hackathon_manager || is_hackathon_creator
                                "
                            >
                                <div slot-scope="{ row }" class="table-actions">
                                    <el-tooltip content="Edit" placement="top">
                                        <a
                                            href="#!"
                                            @click.prevent="cru_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Prize"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </el-tooltip>
                                    <el-tooltip
                                        content="Delete"
                                        placement="top"
                                    >
                                        <a
                                            href="#!"
                                            @click.prevent="d_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Prize"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-table-column>
                        </el-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

// Queries
import { GET_HACKATHON_PRIZES } from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_PRIZE,
    UPDATE_HACKATHON_PRIZE,
    DELETE_HACKATHON_PRIZE,
} from "@/graphql/mutations";

export default {
    name: "HackthonCrudPrizesTable",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        HtmlEditor,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_prizes: {
            query: GET_HACKATHON_PRIZES,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.table_data = [];

                flattened.allHackathonPrizes.forEach((el) => {
                    this.table_data.push({
                        id: el.id,
                        description: el.description,
                        position: el.position,
                    });
                });

                this.table_data = this.sort_by_key(this.table_data, "position");

                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
                this.is_hackathon_manager =
                    res.data.hackathonHackathon.isManager;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_prizes = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_prizes: null,
            },
            table_data: [], // All the prizes table data is in here.
            cru_modal: {
                loading: false,
                success: false,

                prize: {
                    id: null,
                    description: "",
                    position: null,
                },
            },
            d_modal: {
                loading: false,
                success: false,
                prize: {
                    id: null,
                    position: null,
                    description: null,
                },
            },

            is_hackathon_manager: false,
            is_hackathon_creator: false,
        };
    },
    methods: {
        //#region Modals

        // cru_modal
        cru_modal_show(prize) {
            if (this.is_hackathon_manager) {
                if (prize) {
                    this.cru_modal.prize = prize;
                } else {
                    this.cru_modal.prize = {
                        id: null,
                        description: "",
                        position: null,
                    };
                }
                this.$bvModal.show("cru_modal");
            } else {
                this.cru_modal.prize = {
                    id: null,
                    description: "",
                    position: null,
                };
            }
        },
        cru_modal_save() {
            this.cru_modal.loading = true;

            let vars = {
                description: this.cru_modal.prize.description,
                position: Number(this.cru_modal.prize.position),
            };

            let mutation = CREATE_HACKATHON_PRIZE;
            if (this.cru_modal.prize.id) {
                mutation = UPDATE_HACKATHON_PRIZE;
                vars.prize_id = this.cru_modal.prize.id;
            } else {
                vars.hackathon_id = this.hackathon_id;
            }

            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: vars,
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_prizes.refetch();

                    this.cru_modal.loading = false;

                    this.cru_modal.success = true;
                    setTimeout(() => {
                        this.cru_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("cru_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully saved prize`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.cru_modal.loading = false;
                    this.$notify({
                        message: `Could not save prize`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        // d_modal
        d_modal_show(prize) {
            if (this.is_hackathon_manager) {
                if (prize) {
                    this.d_modal.prize = prize;
                } else {
                    this.d_modal.prize = {
                        id: null,
                        description: "",
                        position: null,
                    };
                }
                this.$bvModal.show("d_modal");
            } else {
                this.d_modal.prize = {
                    id: null,
                    description: "",
                    position: null,
                };
            }
        },
        delete_prize() {
            this.d_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_PRIZE,
                    variables: {
                        prize_id: this.d_modal.prize.id,
                    },
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_prizes.refetch();

                    this.d_modal.loading = false;

                    this.d_modal.success = true;
                    setTimeout(() => {
                        this.d_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("d_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully deleted prize`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.d_modal.loading = false;
                    this.$notify({
                        message: `Could not delete prize`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        //#endregion

        sort_by_key(array, key) {
            return array.sort(function (a, b) {
                var x = a[key];
                var y = b[key];
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },

        get_position_suffix(position) {
            if (position == null) {
                return "";
            }
            if (position > 10 && position < 20) {
                return "th";
            } else if (String(position).endsWith("1")) {
                return "st";
            } else if (String(position).endsWith("2")) {
                return "nd";
            } else if (String(position).endsWith("3")) {
                return "rd";
            } else {
                return "th";
            }
        },
    },
    watch: {
        hackathon_id() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_prizes
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_prizes
                );
            }
        },
    },
    mounted() {
        if (!this.$apollo.queries.get_hackathon_prizes.skip) {
            this.$apollo.queries.get_hackathon_prizes.refetch();
        }
    },
    created() {
        // setting this queries fetch policy
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_prizes
        );
    },
};
</script>

<style></style>
